import React from "react"
import PropTypes from "prop-types"
import Email from "./email"
import Phone from "./phone"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const PersonDetail = ({ name, description, image, email, phone, ...props }) => {
  const fallbackImage = useStaticQuery(graphql`
    {
      sanityGeneral {
        logo {
          alt
          asset {
            gatsbyImage(width: 125, height: 125, placeholder: BLURRED)
          }
        }
      }
    }
  `).sanityGeneral.logo
  const profilePic = image || fallbackImage
  const imageData = getImage(profilePic.asset)
  return (
    <div className={`d-flex flex-column flex-md-row ${props.className}`}>
      <div className="text-center text-md-left">
        <GatsbyImage
          className="mr-md-4 mb-3"
          image={imageData}
          alt={profilePic.alt}
        />
      </div>
      <div className="text-center text-md-left">
        <h3>{name}</h3>
        <p className={"mb-1"}>{description}</p>
        <div>
          {email && (
            <>
              <Email email={email} />
              <br />
            </>
          )}
          {phone && (
            <>
              <Phone phone={phone} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

PersonDetail.propTypes = {
  name: PropTypes.string,
  image: PropTypes.object,
  description: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  className: PropTypes.string,
}

export default PersonDetail
