import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import PersonDetail from "../components/person-detail"
import { globalHistory } from "@reach/router"
import { scrollToRef } from "../utils/dom-utils"
import LinkBack from "../components/link-back"
import { graphql } from "gatsby"

const TrainingPage = ({ location, data }) => {
  const tenniscampRef = useRef(null)
  const jugendRef = useRef(null)
  const erwachseneRef = useRef(null)
  const trainerRef = useRef(null)
  const coaches = data.sanityGeneral.coaches

  const navigateToHash = hash => {
    if (!hash) return
    switch (hash) {
      case "#tenniscamp":
        scrollToRef(tenniscampRef)
        break
      case "#jugend":
        scrollToRef(jugendRef)
        break
      case "#erwachsene":
        scrollToRef(erwachseneRef)
        break
      case "#trainer":
        scrollToRef(trainerRef)
        break
      default:
        break
    }
  }

  setTimeout(() => navigateToHash(location.hash), 100)

  useEffect(() => {
    // whenever the route changes we change the tab depending on the hash of the url
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hash = globalHistory.location.hash
        navigateToHash(hash)
      }
    })
  })
  return (
    <Layout>
      <Seo title="Training" />
      <section
        id="banner-training"
        className="banner d-flex flex-column justify-content-end"
      >
        <Container
          className="text-center text-md-left"
          style={{ maxWidth: "900px" }}
        >
          <p>
            <LinkBack to="/" label={"Homepage"} />
          </p>
          <h1>Training</h1>
        </Container>
      </section>
      <Container
        className="text-left text-md-left"
        style={{ maxWidth: "900px" }}
      >
        <section>
          <p>
          Auf unserer Tennisanlage des WTHC bieten zurzeit vier selbständig tätige Trainier 
          ihr professionelles Tennistraining für Kinder und Erwachsene an. In Gruppenunterricht 
          oder Einzelstunden sorgen die Trainer dafür, dass euer Aufschlag wieder 
          so richtig in Schwung kommt. Für Kinder und Jugendliche bieten die Trainer 
          zusätzlich ein alljährliches Tenniscamp in den Sommerferien an. Also, wenn ihr schon 
          immer einmal die ein- oder beidhändige Rückhand lernen wolltet... 
          Die Trainer freuen sich auf euch!
          </p>
        </section>
        <section>
          <h2 ref={erwachseneRef}>Erwachsene</h2>
          <p>
            Die Trainer bieten Gruppen- und Einzeltraining für Erwachsene an.
          </p>
          <p>
          Vereinbart jederzeit gerne ein Probetraining mit einem der Trainer. 
          Ihr könnt die Trainer entweder auf der Tennisanlage ansprechen 
          (Juri trainiert auf Platz 5, Knut auf Platz 6 und Michael bzw. 
          Ulli auf Platz 7). Oder ihr schreibt einfach eine E-Mail direkt 
          an einen der selbständig tätigen Trainer. Alle Einzelheiten zum 
          Training vereinbart ihr direkt und ausschließlich mit eurem jeweiligen 
          Trainer. Wenn keiner der Trainer für euch passende freie Trainingskapazitäten 
          hat, könnt ihr euch auch im Sekretariat bei Frau Boye (buero@wthc-volksdorf.de) 
          auf eine Warteliste setzen lassen.
          </p>
        </section>
        <section>
          {/* <h2 ref={tenniscampRef}>Jugend Tenniscamp 2021</h2>
          <p>
            <strong>Termin: 26.-30. Juli 2021</strong>
          </p>
          <p>
              Betreuung in der Zeit von 8:30 – 16:30 Uhr. Do bis 20:00 Uhr. Frühstück um 9.00 Uhr	
              Freitag bis 13.00 Uhr( Ende des Camps nach dem Essen ).
              Tennistraining, Turnier, weitere sportlichen Aktivitäten, Verpflegung (incl. Grillen), evt.Ausflüge.
              Preis: 229,- € incl. Verpflegung, tägl. Versorgung mit Getränken, evt. Ausflüge.
              Hinweis: in der Zeit der sportlichen Aktivitäten verzichten wir auf das Handy und / oder deren Spiele!!!
          </p>
          <p>
              <strong>Anmeldungen direkt hier per &nbsp; </strong>
              <a href="mailto:michael.boettcher@wthc-volksdorf.de; knut.peyer@wthc-volksdorf.de?&subject=Anmeldung zum Tenniscamp 2021&body=Hiermit melde ich folgende Person verbindlich zum WTHC Tenniscamp vom 26. bis zum 30 Juli 2021 an: 
              %0D%0A
              %0D%0A
              Name:%20
              %0D%0A
              %0D%0A
              Vorname:%20
              %0D%0A
              %0D%0A
              Straße/Nr.:%20
              %0D%0A
              %0D%0A
              PLZ/Ort:%20                                  
              %0D%0A
              %0D%0A
              Tel. (tagsüber):%20
              %0D%0A
              %0D%0A
              E-Mail:%20                       
              %0D%0A
              %0D%0A
              Alter:%20">    
              Email</a>
          </p>
          <p>
              oder bei Michael Böttcher: &nbsp; 
              <a href="mailto:michael.boettcher@wthc-volksdorf.de?&cc=knut.peyer@wthc-volksdorf.de&subject=Anmeldung zum Tenniscamp 2021&body=Hiermit melde ich folgende Person verbindlich zum WTHC Tenniscamp vom 26. bis zum 30 Juli 2021 an: 
              %0D%0A
              %0D%0A
              Name:%20
              %0D%0A
              %0D%0A
              Vorname:%20
              %0D%0A
              %0D%0A
              Straße/Nr.:%20
              %0D%0A
              %0D%0A
              PLZ/Ort:%20                                  
              %0D%0A
              %0D%0A
              Tel. (tagsüber):%20
              %0D%0A
              %0D%0A
              E-Mail:%20                       
              %0D%0A
              %0D%0A
              Alter:%20">    
              michael.boettcher@wthc-volksdorf.de</a> &nbsp; 
              Tel.: 0171 - 263 55 20
              <br/>
              oder bei Knut Peyer: &nbsp; 
              <a href="mailto:knut.peyer@wthc-volksdorf.de?&cc=michael.boettcher@wthc-volksdorf.de&subject=Anmeldung zum Tenniscamp 2021&body=Hiermit melde ich folgende Person verbindlich zum WTHC Tenniscamp vom 26. bis zum 30 Juli 2021 an: 
              %0D%0A
              %0D%0A
              Name:%20
              %0D%0A
              %0D%0A
              Vorname:%20
              %0D%0A
              %0D%0A
              Straße/Nr.:%20
              %0D%0A
              %0D%0A
              PLZ/Ort:%20                                  
              %0D%0A
              %0D%0A
              Tel. (tagsüber):%20
              %0D%0A
              %0D%0A
              E-Mail:%20                       
              %0D%0A
              %0D%0A
              Alter:%20">    
              knut.peyer@wthc-volksdorf.de</a> &nbsp;
              Tel.: 0179 794 36 33
              <br/>
              <br/>
              Das Tenniscamp ist auf max. 30 Teilnehmer beschränkt und die zeitliche Anmeldung entscheidet über die Teilnahme!!!
          </p>
          <br/>
          <br/> */}
          <h2 ref={jugendRef}>Training für Jugendliche</h2>
          <p>
            Die Trainer bieten Gruppen- und Einzeltraining auch für Jugendliche
            an.
          </p>
          <p>
          Das Jugendtraining bieten die Trainer grundsätzlich in der Woche täglich 
          zwischen 15.00 (nach Absprache ab 14:00) Uhr und 19:00 Uhr an. Für Kinder, 
          die noch nicht wissen, ob ihnen das Jugendtraining Spaß macht, bieten die 
          Trainer ein Probetraining an. Für die Teilnahme am Jugendtraining bzw. an dem 
          Probetraining könnt ihr die Trainer entweder auf der Tennisanlage ansprechen 
          (Juri trainiert auf Platz 5, Knut auf Platz 6 und Michael bzw. Ulli auf Platz 7). 
          Oder ihr schreibt einfach eine E-Mail direkt an einen der selbständig tätigen 
          Trainer. Alle Einzelheiten zum Training vereinbart ihr direkt und ausschließlich 
          mit dem jeweiligen Trainer eurer Kinder. Wenn keiner der Trainer für euch 
          passende freie Trainingskapazitäten hat, könnt ihr euch auch im Sekretariat bei 
          Frau Boye <a href="mailto:buero@wthc-volksdorf.de">buero@wthc-volksdorf.de</a> 
          auf eine Warteliste setzen lassen.
          </p>
          <p>
          Die Verträge, die die Trainer für die Teilnahme am Jugendtrainging mit euch 
          abschließen, regeln das Jugendtraining zurzeit so, dass innerhalb der Gruppen 
          das Training saisonübergreifend automatisch weiterläuft. Der Trainingsbeitrag 
          wird monatlich ganzjährig von dem jeweiligen Trainer berechnet und per Lastschrift 
          jeweils zum 05. des Monats eingezogen. Die Kündigungsfrist für das Jugendtraining 
          ist jeweils für die Sommersaison zum 15.04. und für die Wintersaison zum 15.09.
          möglich und ist dem entsprechenden Trainer schriftlich mitzuteilen. 
          Terminänderungen (z.B. aufgrund anderer Verpflichtungen) sowie alle anderen 
          Einzelheiten sind direkt und ausschließlich mit dem jeweiligen Trainer abzusprechen.
          </p>
          <p>
            <strong>
              Preise für das Jugendtraining gemäß Angabe der Trainer (ohne
              Gewähr durch den Verein):
            </strong>
            <br />
            Training € 45,-- monatlich; in der Gruppe bis zu 5 Teilnehmern
            <br />
            Training € 60,-- monatlich; In der Gruppe bis zu 3 Teilnehmern
            <br />
            2er Trainingsgruppen sind mit dem jeweiligen Trainer abzusprechen.
            <br />
            Die Preise gelten je Teilnehmer. Die Unterrichtsdauer beträgt 60
            Min.
            <br />
            <br />
            Im Winter ist noch eine ermäßigte Hallengebühr von 55 EUR pro
            Gruppentrainingsteilnehmer an den Verein zu zahlen.
          </p>
          <p>
            Am Samstagvormittag zwischen 09.00 und 13:00 Uhr bietet Juri
            zusätzlich ein <b>Fördertraining</b> für alle jugendlichen
            Mannschaftsspieler(innen) an. Wer an diesem Fördertraining
            teilnehmen möchte, kann sich darüber freuen, dass der Verein den
            Teilnehmer(inne)n die Hälfte der Trainerkosten für das
            Fördertraining erstattet; dazu erhält jede(r) Teilnehmer(in) am Ende
            einer Saison von Juri eine <b>Teilnahmebescheinigung</b> nebst
            Ausweis der gezahlten Trainerkosten, die dann beim Verein zwecks
            Kostenerstattung einzureichen ist.
            <br />
            <br />
            In den Schulferien und an Feiertagen ist üblicherweise kein
            Training.
          </p>
          {/* <p>
            Alle Jugend-Trainingsanfragen können an die Mailadresse
            <br/>
            <strong>
            <a href="mailto:jugendtraining@wthc-volksdorf.de">   
            jugendtraining@wthc-volksdorf.de</a>
            </strong>
            <br/>
            gestellt werden. Unserer Trainer Knut Peyer wird sich um die Anfrage kümmern.
          </p> */}
        </section>
        <section>
          <h2 className="mb-3" ref={trainerRef}>
            Unsere Trainer
          </h2>
          {coaches.map(({ name, image, description, phone, email }, index) => (
            <PersonDetail
              key={`coach-${index}`}
              className="mb-3"
              image={image}
              name={name}
              description={description}
              phone={phone}
              email={email}
            />
          ))}
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityGeneral {
      coaches {
        name
        email
        phone
        description
        image {
          alt
          asset {
            gatsbyImage(width: 125, height: 150, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default TrainingPage
